export const SAVE_DATA_SOURCE_CLICK = "SAVE_DATA_SOURCE_CLICK";
export const TEXT = "TEXT";
export const Sign_Header = "Send Appsmith signature header";
export const Is_Session_Enabled = "isSendSessionEnabled";
export const SESSION_SIGNATURE_KEY = "sessionSignatureKey";
export const SESSION_LABLE = "Session details signature key";
export const USE_SELF_SIGNED_CERTIFICATE_TEXT = "Use Self-Signed Certificate";
export const URL_REGEX = "^(http|https)://";
export const PLACEHOLDER_TEXT = "https://example.com";
export const AUTHENTICATION = "Authentication";
export const UPLOAD_CERTIFICATE = "Upload Certificate";
export const HEADER_PREFIX = "Header prefix";
export const BEARER_PLACEHOLDER_TEXT = "eg: Bearer ";
export const BEARER_TOKEN = "Bearer token";
export const QUERY_PARAMETERS = "Query parameters";
export const USERNAME = "Username";
export const PASSWORD = "Password";
export const CLIENT_CREDENTIALS = "Client Credentials";
export const AUTHORIZATION_CODE = "Authorization Code";
export const GRANT_TYPE = "Grant type";
export const REQUEST_HEADER = "Request Header";
export const REQUEST_URL = "Request URL";
export const ADD_ACCESS_TOKEN_TO = "Add Access Token To";
export const IS_TOKEN_HEADER = "isTokenHeader";
export const ACCESS_TOKEN_URL = "Access token URL";
export const PLACEHOLDER_TEXT_URL =
  "https://example.com/login/oauth/access_token";
export const CLIENT_ID = "Client ID";
export const CLIENT_SECRET = "Client secret";
export const PASSWORD_UPPER = "PASSWORD";
export const SCOPE_LABEL = "Scope(s)";
export const SCOPE_PLACEHOLDER_TEXT = "e.g. read, write";
export const AUTHORIZATION_EXPIRATION_LABEL =
  "Authorization expires in (seconds)";
export const EXIPIRATION_PLACEHOLDER_TEXT = "3600";
export const NUMBER = "NUMBER";
export const SEND_AS_BASIC_AUTH_HEADER = "Send as Basic Auth header";
export const SEND_CLIENT_CREDENTIALS_IN_BODY =
  "Send client credentials in body";
export const CLIENT_AUTHENTICATION = "Client Authentication";
export const ADVANCED_SETTINGS = "Advanced Settings";
export const YES = "Yes";
export const NO = "No";
export const SEND_SCOPE_WITH_REFRESH_TOKEN = "Send scope with refresh token";
export const SEND_CLIENT_CRENDENTIALS =
  "Send client credentials with (on refresh token):";
export const SSL_CERTIFICATE_AUTHORIZED_REQUESTS =
  "Use Self-Signed Certificate for Authorization requests";
export const AUDIENCE_LABEL = "Audience";
export const AUDIENCE_PLACEHOLDER = "https://example.com/oauth/audience";
export const RESOURCE_LABEL = "Resoucre";
export const RESOURCE_PLACEHOLDER = "https://example.com/oauth/resource";
export const AUTHORIZATION_LABEL = "Authorization URL";
export const AUTHORIZATION_PLACEHOLDER =
  "https://example.com/login/oauth/authorize";
export const REDIRECT_URL = "Redirect URL";
export const STYLED_INFO = " Url that the oauth server should redirect to";
export const CUSTOM_AUTHENTICATION_PARAMETERS =
  "Custom Authentication Parameters";
export const ADD_TO = "Add To";
export const HEADER = "Header";
export const QUERY_PARAMS = "Query Params";
export const KEY_LABEL = "Key";
export const API_KEY = "api_key";
export const GRANTTYPE = "grantType";
export const HEADERPREFIX = "headerPrefix";
export const BEARER = "Bearer";
export const SENDSCOPEWITHREFRESHTOKEN = "sendScopeWithRefreshToken";
export const ISAUTHORIZATIONHEADER = "isAuthorizationHeader";
export const REFRESH_TOKEN = "refreshTokenClientCredentialsLocation";
